<template>
  <div class="posts">
    <div class="posts-head" v-if="model === 'public'">
      <div class="avatar">
        <a-avatar
          :size="50"
          :src="require(`@/assets/image/bbs/anonymous.png`)"
          v-if="data.isAnonymous === 1"
        />
        <a-avatar
          :size="50"
          :src="data.portrait || require(`@/assets/image/bbs/avatar.png`)"
          v-else
        >
          <OpenData type="userName" :openid="data.createUser" />
        </a-avatar>
      </div>
      <div class="text">
        <div class="name">
          <span v-if="data.isAnonymous === 1">{{
            $t("bbs.anonymous_user")
          }}</span>
          <!-- 匿名用户 -->
          <template v-else>
            <OpenData type="userName" :openid="data.createUser" />
            <div
              class="comment-medal"
              v-if="data.mediaImage && companyInfo.menu.includes('medal')"
            >
              <div class="medal-name">
                <img draggable="false" :src="data.mediaImage" alt="" />
                {{ data.medalName }}
              </div>
            </div>
          </template>
        </div>
        <div class="topic">
          <span
            class="a"
            v-for="item in data.topics || []"
            :key="item.topicId"
            @click="topicJump(item)"
          >
            #{{ item.topicName }}#
          </span>
        </div>
      </div>
      <tag
        class="absolute-tag"
        :text="'悬赏回答' + data.rewardIntegral + '积分'"
        v-if="data.rewardIntegral"
      />
      <!-- 悬赏回答  积分-->
    </div>
    <div class="posts-box">
      <div class="pl64" v-if="model === 'public'"></div>
      <div>
        <h3
          class="posts-title"
          @click="subjectJump(0)"
          v-if="data.subjectType !== 1"
        >
          <tag
            text="置顶"
            v-if="data.isGlobalTop === 1 || (isGroup && data.isGlobalTop === 2)"
          />
          <a-tag
            :style="{ color: '#FF7A45' }"
            color="#FFF2E8"
            v-if="data.coverVideoss"
          >
            <span class="tag-play">
              <span class="tag-play-icon"></span>{{ $t("Pub_Video") }}
              <!-- 视频 -->
            </span>
          </a-tag>
          <span v-html="data.title"></span>
        </h3>
        <div class="posts-content">
          <div
            class="posts-content-media"
            v-if="data.coverImgss"
            @click="previewImg"
          >
            <img :src="data.coverImgss[0]" />
            <span class="total" v-if="data.subjectType !== 3"
              >{{ data.coverImgss.length }}{{ $t("pic") }}</span
            >
            <!-- 图 -->
          </div>
          <div
            class="posts-content-media"
            v-else-if="data.coverVideoss && data.coverVideoss.length"
          >
            <video
              :src="
                (
                  data.coverVideoss[0].filePath.slice(
                    0,
                    data.coverVideoss[0].filePath.lastIndexOf('.')
                  ) + '/1/mp4/1_1.mp4'
                ).replace('/source/', '/')
              "
            />
            <div
              class="mask"
              @click="previewVideo(data.coverVideoss[0].filePath)"
              v-if="data.coverVideoss[0].status === 1"
            >
              <PlayCircleFilled class="play" />
            </div>
            <div class="mask" style="cursor: default" v-else>
              <span class="status">
                {{ $t("bbs.video", [fileStatus[data.coverVideoss[0].status]]) }}
                <!-- 视频 -->
              </span>
            </div>
          </div>
          <div
            class="posts-content-deatil"
            :style="{
              width:
                model === 'public'
                  ? data.coverVideoss || data.coverImgss
                    ? '500px'
                    : '780px'
                  : data.coverVideoss || data.coverImgss
                  ? '686px'
                  : '966px',
            }"
            @click="subjectJump(0)"
          >
            <div class="content">
              <template v-if="data.subjectType === 1">
                <tag
                  text="置顶"
                  v-if="
                    data.isGlobalTop === 1 ||
                    (isGroup && data.isGlobalTop === 2)
                  "
                />
              </template>
              <tag text="精华" v-if="data.isCondensed === 1" /><a-tag
                :style="{ color: '#FF7A45' }"
                color="#FFF2E8"
                v-if="data.subjectType === 1 && data.coverVideoss"
                ><span class="tag-play"
                  ><span class="tag-play-icon"></span
                  >{{ $t("Pub_Video") }}</span
                ></a-tag
              ><span v-html="data.contents"></span>
              <!-- 视频 -->
            </div>
            <div class="info">
              <p v-if="data.remindUsers && data.remindUsers.length">
                {{ $t("bbs.invited") }}
                <!-- 邀请 -->
                <template
                  v-for="(user, index) in data.remindUsers.slice(0, 3)"
                  :key="index"
                >
                  <OpenData type="userName" :openid="user.realName" />{{
                    index === 2 || index + 1 === data.remindUsers.length
                      ? ""
                      : "、"
                  }}
                </template>
                {{
                  data.remindUsers.length > 3
                    ? $t("bbs.nth_people", [data.remindUsers.length])
                    : ""
                }}
                <!-- ...等 3人 -->
              </p>
              <p>
                {{ $t("bbs.form", [" "]) }}
                <!-- 来自 -->
                <span class="a" @click.stop="groupJump">{{
                  data.groupName
                }}</span>
              </p>
            </div>
          </div>
        </div>
        <div class="posts-file" v-if="data.links || data.attachmentList">
          <a
            target="_blank"
            :href="
              data.links.includes('?')
                ? `${data.links}&ddtab=true`
                : `${data.links}?ddtab=true`
            "
            class="item link"
            v-if="data.links"
          >
            <div class="icon">
              <LinkOutlined style="color: #fff; font-size: 20px" />
            </div>
            <div class="info">
              <p>{{ data.links }}</p>
              <span>
                {{
                  data.links.indexOf(currHost) === -1
                    ? $t("bbs.form", [$t("bbs.external_link")])
                    : $t("bbs.form", [$t("bbs.internal_link")])
                }}
                <!-- 来自外部链接 : 来自内部链接 -->
              </span>
            </div>
          </a>
          <template v-for="(item, index) in data.attachmentList" :key="index">
            <!-- zip文件不支持预览 -->
            <a-tooltip
              :title="$t('bbs.zip_not_preview')"
              v-if="
                item.filePath
                  .substr(item.filePath.lastIndexOf('.'))
                  .toLowerCase() == '.zip'
              "
            >
              <div class="item">
                <div class="icon">
                  <FilePptOutlined style="color: #fff; font-size: 20px" />
                </div>
                <div class="info">
                  <p style="cursor: default">
                    {{ item.fileName + ".zip" }}
                  </p>
                  <a
                    @click="fileDownload(item.sourceFilePath, item.fileName)"
                    v-if="data.isDown === 1"
                    >{{ $t("bbs.click_download") }}</a
                  >
                  <!-- 点击下载 -->
                </div>
              </div>
            </a-tooltip>
            <template v-else>
              <a
                v-if="item.status === 1"
                class="item"
                target="_blank"
                :href="
                  '/preview?ddtab=true&filePath=' +
                  item.filePath.replace('/source/', '/')
                "
              >
                <div class="icon">
                  <FilePptOutlined style="color: #fff; font-size: 20px" />
                </div>
                <div class="info">
                  <p>
                    {{
                      item.fileName +
                      item.filePath.substr(item.filePath.lastIndexOf("."))
                    }}
                  </p>
                  <a
                    @click.stop.prevent="
                      fileDownload(item.sourceFilePath, item.fileName)
                    "
                    v-if="data.isDown === 1"
                    >{{ $t("bbs.click_download") }}</a
                  >
                  <!-- 点击下载 -->
                </div>
              </a>
              <a v-else class="item" href="javascript:;">
                <div class="icon">
                  <FilePptOutlined style="color: #fff; font-size: 20px" />
                </div>
                <div class="info">
                  <p>
                    {{
                      item.fileName +
                      item.filePath.substr(item.filePath.lastIndexOf("."))
                    }}
                  </p>
                  <a href="javascript:;">
                    {{ $t("bbs.attachment", [fileStatus[item.status]]) }}
                    <!-- 附件 -->
                  </a>
                </div>
              </a>
            </template>
          </template>
        </div>
        <div class="posts-foot">
          <span class="time">{{ timeFrom(data.createTime * 1000) }}</span>
          <div class="ctrl" v-if="model === 'mine-draft'">
            <div class="ctrl-item pointer" @click="edit">
              <FormOutlined /><i>{{ $t("bbs.edit") }}</i>
              <!-- 编辑 -->
            </div>
            <div class="ctrl-item pointer" @click="submit">
              <FormOutlined /><i>{{ $t("bbs.publish") }}</i>
              <!-- 发布 -->
            </div>
          </div>
          <div class="ctrl" v-else>
            <div class="ctrl-item">
              <EyeOutlined /><i>{{ data.views }}</i>
            </div>
            <div
              class="ctrl-item pointer"
              @click="subjectJump(1)"
              v-if="data.subjectType === 2"
            >
              <span class="anticon anticon-message">
                <svg viewBox="64 64 896 896" width="1em" height="1em">
                  <path
                    d="M853.333333 768c35.413333 0 64-20.650667 64-55.978667V170.581333A63.978667 63.978667 0 0 0 853.333333 106.666667H170.666667C135.253333 106.666667 106.666667 135.253333 106.666667 170.581333v541.44C106.666667 747.285333 135.338667 768 170.666667 768h201.173333l110.016 117.44a42.752 42.752 0 0 0 60.586667 0.042667L651.904 768H853.333333z m-219.029333-42.666667h-6.250667l-115.797333 129.962667c-0.106667 0.106667-116.010667-129.962667-116.010667-129.962667H170.666667c-11.776 0-21.333333-1.621333-21.333334-13.312V170.581333A21.205333 21.205333 0 0 1 170.666667 149.333333h682.666666c11.776 0 21.333333 9.536 21.333334 21.248v541.44c0 11.754667-9.472 13.312-21.333334 13.312H634.304zM341.333333 490.666667a42.666667 42.666667 0 1 0 0-85.333334 42.666667 42.666667 0 0 0 0 85.333334z m170.666667 0a42.666667 42.666667 0 1 0 0-85.333334 42.666667 42.666667 0 0 0 0 85.333334z m170.666667 0a42.666667 42.666667 0 1 0 0-85.333334 42.666667 42.666667 0 0 0 0 85.333334z"
                    fill="#7a859b"
                  ></path>
                </svg>
              </span>
              <i>{{ data.answers || $t("bbs.i_will_answer") }}</i>
              <!-- 我来回答 -->
            </div>
            <div class="ctrl-item pointer" @click="subjectJump(1)" v-else>
              <MessageOutlined /><i>{{
                data.comments || $t("bbs.i_will_comment")
              }}</i>
              <!-- 我来评论 -->
            </div>
            <div class="ctrl-item">
              <LikeOutlined
                class="pointer"
                @click="toLike"
                :class="{ ed: data.isLike === 1 }"
              /><i>{{ data.likes }}</i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";
import { computed, getCurrentInstance } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { timeFrom, fileDownload } from "@/utils/tools";
import { debounce } from "lodash";
import { likeSubject } from "@/api/bbs";
import checkJump from "@/views/bbs/utils/checkJump";
import OpenData from "@/components/OpenData.vue";
import tag from "./tag.vue";
export default {
  name: "posts",
  components: {
    OpenData,
    tag,
  },
  props: {
    model: {
      type: String,
      default: "public",
    },
    data: {
      type: Object,
      required: true,
    },
    newWindow: {
      type: Boolean,
      default: false,
    },
    isGroup: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["previewVideo", "postsEdit", "postsSubmit"],
  setup(props, { emit }) {
    const { t: $t } = useI18n();
    const { proxy } = getCurrentInstance();
    const router = useRouter();
    const store = useStore();
    const companyInfo = computed(() => store.getters.companyInfo);

    const previewImg = () => {
      proxy.$viewerApi({
        options: {
          modal: true,
          title: false,
        },
        images: props.data.coverImgss,
      });
    };

    const previewVideo = (url) => {
      emit("previewVideo", url);
    };

    const edit = () => {
      emit("postsEdit");
    };

    const submit = () => {
      emit("postsSubmit");
    };

    const toLike = debounce(() => {
      if (props.data.state < 8) {
        proxy.$message.error($t("bbs.join_corcle_like"));
        // 加入圈子才能点赞哦！
      } else {
        likeSubject({ id: props.data.subjectId }).then((res) => {
          if (res.ret == 0) {
            let isLike = props.data.isLike === 1;
            props.data.isLike = isLike ? 2 : 1;
            isLike ? props.data.likes-- : props.data.likes++;
          }
        });
      }
    }, 500);

    const subjectJump = (toComment) => {
      if (props.data.status !== 1 && props.data.status !== 8) {
        proxy.$message.warn($t("bbs.please_publish"));
        // 请先发布
        return false;
      }
      checkJump(props.newWindow, props.data, toComment);
    };

    const topicJump = (item) => {
      let url = `/bbs/topic?topicId=${item.topicId}`;
      if (props.newWindow) {
        window.open(url + "&ddtab=true");
      } else {
        window.location.href = url;
      }
    };

    const groupJump = () => {
      if (props.newWindow) {
        window.open(`/bbs/group?ddtab=true&groupId=${props.data.groupId}`);
      } else {
        router.push({
          path: "/bbs/group",
          query: {
            groupId: props.data.groupId,
          },
        });
      }
    };

    return {
      companyInfo,
      currHost: location.host,
      timeFrom,
      fileDownload,
      previewImg,
      previewVideo,
      edit,
      submit,
      toLike,
      subjectJump,
      topicJump,
      groupJump,
      fileStatus: {
        10: $t("bbs.file_status_1", 1), // 未转换
        11: $t("bbs.file_status_1", 2), // 待转换
        12: $t("bbs.file_status_2", 1), // 转换中
        13: $t("bbs.file_status_2", 2), // 转换失败
      },
    };
  },
};
</script>

<style lang="less" scoped>
.posts {
  .a {
    &:hover {
      color: @color-theme!important;
    }
  }
  .tag-play {
    .mixinFlex(flex-start; center);
    &-icon {
      width: 12px;
      height: 12px;
      background-color: #ff7a45;
      display: inline-block;
      border-radius: 2px;
      position: relative;
      margin-right: 3px;
      &::after {
        content: "";
        width: 0;
        height: 0;
        border-left: 5px solid #fff2e8;
        border-top: 3px solid transparent;
        border-bottom: 3px solid transparent;
        position: absolute;
        top: 3px;
        left: 4px;
      }
    }
  }
  &-head {
    .mixinFlex(space-between);
    margin-bottom: 8px;
    position: relative;
    .avatar {
      width: 50px;
    }
    .text {
      width: 800px;
      padding-left: 14px;
      font-size: 14px;
      .mixinFlex(center; flex-start; column);
      .name {
        color: #202020;
        line-height: 22px;
      }
      .topic {
        .a {
          display: inline-block;
          color: @color-theme;
          margin-right: 8px;
          cursor: pointer;
        }
      }
    }
    .absolute-tag {
      position: absolute;
      right: 0;
      top: 2px;
    }
  }
  &-box {
    .mixinFlex(space-between);
    .pl64 {
      padding-left: 64px;
    }
  }
  &-title {
    font-size: 16px;
    line-height: 24px;
    font-weight: normal;
    color: #333;
    margin-bottom: 8px;
    cursor: pointer;
  }
  &-content {
    .mixinFlex(space-between);
    cursor: pointer;
    &-media {
      width: 260px;
      height: 150px;
      margin-right: 20px;
      background-color: #f5f5f5;
      flex: none;
      position: relative;
      cursor: pointer;
      video,
      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
      .mask {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background-color: rgba(0, 0, 0, 0.1);
        .mixinFlex(center; center);
        .play {
          font-size: 45px;
          color: #fff;
        }
        .status {
          font-size: 15px;
          color: #f00;
          letter-spacing: 1px;
          user-select: none;
        }
      }
      .total {
        position: absolute;
        bottom: 10px;
        right: 10px;
        background-color: rgba(0, 0, 0, 0.55);
        color: #fff;
        border-radius: 2px;
        line-height: 22px;
        font-size: 14px;
        padding: 0 6px;
      }
    }
    &-deatil {
      .mixinFlex(space-between; flex-start; column);
      .content {
        width: 100%;
        font-size: 14px;
        color: #333;
        .mixinEllipsis(66px, 3);
      }
      .info {
        font-size: 14px;
        color: #7a859b;
        margin-top: 8px;
        width: 100%;
        p {
          margin: 2px 0 0 0;
          .mixinEllipsis(20px);
          .a {
            color: #7a859b;
          }
        }
      }
    }
  }
  &-file {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    .item {
      padding: 10px 14px;
      background-color: #fafafa;
      border-radius: 4px;
      margin-bottom: 8px;
      min-width: 49%;
      .mixinFlex(space-between);
      &.link {
        cursor: pointer;
      }
      &:first-of-type {
        flex-grow: 1;
      }
      &:nth-child(2n) {
        margin-left: 2%;
      }
      .icon {
        width: 40px;
        height: 40px;
        background-color: var(--theme);
        border-radius: 2px;
        .mixinFlex(center; center);
      }
      .info {
        width: calc(100% - 50px);
        .mixinFlex(center; flex-start; column);
        font-size: 12px;
        p {
          width: 304px;
          color: #333;
          .mixinEllipsis(20px);
          margin: 0;
          cursor: pointer;
        }
        span {
          color: #999;
          cursor: pointer;
        }
      }
    }
  }
  &-foot {
    margin-top: 20px;
    font-size: 14px;
    line-height: 22px;
    color: #7a859b;
    .mixinFlex(space-between; center);
    .ctrl {
      user-select: none;
      .mixinFlex(flex-start; center);
      &-item {
        margin-left: 20px;
        .mixinFlex(flex-start; center);
        &.pointer {
          cursor: pointer;
        }
        .pointer {
          cursor: pointer;
        }
        .ed {
          color: @color-theme;
        }
        i {
          font-style: mormal;
          margin-left: 5px;
        }
      }
    }
  }
}
.comment-medal {
  padding-left: 13px;
  margin-left: 5px;
  .mixinFlex(flex-start, center);
  display: inline-flex;
  .medal-name {
    display: inline-block;
    min-width: 52px;
    height: 16px;
    background: linear-gradient(
      90deg,
      rgba(252, 106, 43, 1) 0%,
      rgba(222, 205, 58, 1) 100%
    );
    font-size: 12px;
    line-height: 16px;
    color: #fff;
    padding: 0 8px 0 14px;
    border-radius: 0 8px 8px 0;
    position: relative;
    img {
      width: 24px;
      height: 24px;
      position: absolute;
      left: -13px;
      top: -4px;
    }
  }
}
</style>
